import background from "../../assets/image/background.png";

const Slide6 = () => {
  return (
    <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full text-white">
      <p className="text-[24px] sm:text-[48px] font-bold pb-6 z-10 absolute top-8 tracking-wider px-4">
        The tech is hard.
      </p>
      <div className="flex flex-col gap-8 tracking-wider text-[15px] sm:text-[28px] px-4">
        <p className="z-10">
          &#8226; Real-time conversation is an extreme technical challenge.
        </p>
        <p className="z-10">
          &#8226; Our solution combines novel ML approaches with hardcore
          engineering.
        </p>
        <p className="z-10">
          &#8226; Most of our pilot customers came to us after trying to build
          their own.
        </p>
      </div>
      <img
        src={background}
        alt="background"
        className="absolute w-full h-full"
      />
    </div>
  );
};

export default Slide6;
