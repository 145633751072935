import background from "../../assets/image/background.png";
import photo1 from "../../assets/image/photo1.jpg";
import anon from "../../assets/image/ANON.png";

const Slide7 = () => {
  return (
    <div className="absolute z-10 flex flex-col justify-center w-full h-full text-white">
      <div className="px-[5%] z-10">
        <p className="text-[24px] sm:text-[48px] font-bold pb-6 z-10 absolute top-16 tracking-wider text-start whitespace-nowrap">
          The team is strong.
        </p>
        <div className="flex gap-8 z-10 lg:text-[20px] sm:text-[15px] text-[10px]">
          <div className="w-[25%] flex flex-col items-center">
            <p>BRIAN ATWOOD</p>
            <p className="text-[#cdcdcd] pb-2.5">Founder, CEO</p>
            <img src={photo1} alt="photo1" className="w-[70%] pb-2.5" />
            <p className="lg:text-[17px] text-[12px] text-center">
              Background in software engineering, ML, neuroscience and psycholinguistics.
            </p>
            <p className="lg:text-[17px] text-[12px] text-center pt-2.5">
              Previously CTO / Cofounder, PM, SWE. <br />
              Dropped out of Harvard PhD in Cognition, Brain and Behavior.
            </p>
          </div>
          <div className="w-[25%] flex flex-col items-center">
            <p>ANON</p>
            <p className="text-[#cdcdcd] pb-2.5">CTO</p>
            <img src={anon} alt="anon" className="w-[70%] pb-2.5" />
            <p className="lg:text-[17px] text-[12px] text-center">
              Staff SWE @ Tesla.
            </p>
            <p className="lg:text-[17px] text-[12px] text-center">
              Background in distributed systems, streaming, and technical team
              leadership.
            </p>
          </div>
          <div className="w-[25%] flex flex-col items-center">
            <p>ANON</p>
            <p className="text-[#cdcdcd] pb-2.5">SWE</p>
            <img src={anon} alt="anon" className="w-[70%] pb-2.5" />
            <p className="lg:text-[17px] text-[12px] text-center">
              Senior SWE @ Disney+.
            </p>
            <p className="lg:text-[17px] text-[12px] text-center">
              Background in infrastructure and real-time streaming services.
            </p>
          </div>
          <div className="w-[25%] flex flex-col items-center">
            <p>ANONS</p>
            <p className="text-[#cdcdcd] pb-2.5">ML, Marketing, Design</p>
            <img src={anon} alt="anon" className="w-[70%] pb-2.5" />
            <p className="lg:text-[17px] text-[12px] text-center">
              We have world-class advisors and contractors across ML, Marketing,
              Design, and Business Development.
            </p>
          </div>
        </div>
      </div>
      <img src={background} alt="background" className="fixed w-full h-full" />
    </div>
  );
};

export default Slide7;
