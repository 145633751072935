import background from "../../assets/image/background.png";
import assistant1 from "../../assets/image/assistant1.png";
import assistant2 from "../../assets/image/assistant2.png";
import assistant3 from "../../assets/image/assistant3.png";
import assistant4 from "../../assets/image/assistant4.png";

const Slide3 = () => {
  return (
    <div className="absolute z-10 flex flex-col items-center w-full h-full">
      <p className="text-white text-[24px] sm:text-[48px] font-bold z-10 py-8 tracking-wider px-[5%]">
        Voice Assistants are out.
      </p>
      <div className="flex flex-col gap-4 z-10 text-white self-start px-[5%] tracking-wide text-[15px] sm:text-[24px] sm:pb-0 pb-6">
        <p>&#8226; "Wake words" are clumsy.</p>
        <p>&#8226; Decision trees are stifling.</p>
        <p>&#8226; One-shot requests are often misheard or cut short.</p>
        <p>&#8226; Turn-taking is stilted if it exists.</p>
        <p>&#8226; Agents are painful to configure.</p>
      </div>
      <div className="z-10 flex flex-col w-full mt-auto sm:flex-row">
        <div className="flex sm:w-[50%]">
          <img src={assistant1} alt="assistant1" className="w-[50%]" />
          <img src={assistant2} alt="assistant2" className="w-[50%]" />
        </div>
        <div className="flex sm:w-[50%]">
          <img src={assistant3} alt="assistant3" className="w-[50%]" />
          <img src={assistant4} alt="assistant4" className="w-[50%]" />
        </div>
      </div>
      <img
        src={background}
        alt="background"
        className="absolute w-full h-full"
      />
    </div>
  );
};

export default Slide3;
