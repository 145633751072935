import "./App.css";

import axios from "axios";
import { v4 as uuid } from "uuid";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import PersonaClient from "./components/PersonaClient";
import { useRiveStateMachine } from "./components/RiveComponent";
import Popup from "./components/Popup";
import Slider from "./components/Slider";
import personaAnimation from "./assets/animations/v02.riv";
import { INIT_PAGE } from "./constants";

function App() {
  const [showModal, setShowModal] = useState(true);
  const [personaClient, setPersonaClient] = useState(null);
  const [isLoadingPersona, setIsLoadingPersona] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [mail, setMail] = useState("");
  const [curPage, setCurPage] = useState(INIT_PAGE);
  const [showSlide, setShowSlide] = useState(false);
  const [stateMachineControls, setStateMachineControls] = useState(null);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [shouldStartPersona, setShouldStartPersona] = useState(false);
  const [personaIsReady, setPersonaIsReady] = useState(false);
  const [didShowMoreInfoPopup, setDidShowMoreInfoPopup] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const {
    rive,
    RiveComponent,
    transitionToListening,
    transitionToThinking,
    transitionToSpeaking,
    transitionToSuccessfulResponse,
    transitionToFailResponse,
    transitionToIdle,
  } = useRiveStateMachine({
    src: personaAnimation,
    stateMachineName: "State Machine 1",
    autoplay: true,
  });

  const getUserID = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");

      const userId = res.data.IPv4;
      return userId.replace(/\./g, "_");
    } catch (e) {
      // use localstorage instead
      const userId = localStorage.getItem("scuid");
      if (userId) {
        return userId;
      } else {
        const userId = uuid();
        localStorage.setItem("scuid", userId);
        return userId;
      }
    }
  };
  const handleBegin = async () => {
    setIsLoadingPersona(true);
    const userId = await getUserID();
    setIpAddress(userId);
    if (!shouldStartPersona) setShouldStartPersona(true);

    // getUserID().then((userId) => {
    //   personaClient
    //     .init(userId, "SmarterChild")
    //     .then(() => {
    //       console.log("personaClient initialized");
    //       personaClient.on("ready", () => {
    //         personaClient.sayText(`Hello, welcome
    //         to the Persona interactive pitch deck. I’m Andrea, a Persona created by Sindarin Ventures to
    //         guide you through this interactive pitch deck. You can ask me any question about the company
    //         at any time, or simply ask me to go to the next slide to progress through the pitch.`);
    //       });
    //     })
    //     .catch((err) => {
    //       console.log("personaClient init error", err);
    //       if (/You have/gi.test(err)) {
    //         setIsLimit(true);
    //       }
    //     });
    // });
  };
  useEffect(() => {
    if (
      RiveComponent &&
      transitionToListening &&
      transitionToThinking &&
      transitionToSpeaking &&
      transitionToSuccessfulResponse &&
      transitionToFailResponse &&
      transitionToIdle
    ) {
      // console.log('state machine ready')
      setStateMachineControls({
        transitionToListening,
        transitionToThinking,
        transitionToSpeaking,
        transitionToSuccessfulResponse,
        transitionToFailResponse,
        transitionToIdle,
      });
    }
  }, [
    RiveComponent,
    transitionToListening,
    transitionToThinking,
    transitionToSpeaking,
    transitionToSuccessfulResponse,
    transitionToFailResponse,
    transitionToIdle,
  ]);
  useEffect(() => {
    if (personaIsReady) {
      if (isLoadingPersona) {
        console.log("personaIsReady");
        setIsLoadingPersona(false);
      }
    }
  }, [personaIsReady]);

  const onReady = () => {
    setPersonaIsReady(true);
    if (showModal === true) {
      setShowModal(false);
      setTimeout(() => {
        setShowSlide(true);
      }, 1000);
    }
  };
  const isChrome = () => {
    const userAgentData = navigator.userAgentData;
    if (userAgentData) {
      const brands = userAgentData.brands.map((brand) => brand.brand);
      return brands.includes("Google Chrome");
    }
    return false;
  };
  const handleChangeEmail = (event) => {
    setMail(event.target.value);
  };
  const onSaveEmail = async () => {
    const apiURL = "/.netlify/functions/saveEmail";

    const response = await fetch(apiURL, {
      method: "POST",
      body: JSON.stringify({
        ip: ipAddress,
        email: mail,
      }),
    });
    const data = await response.json();
  };
  const onLimitReached = () => {
    if (!isLimitReached) {
      setIsLimitReached(true);
    }
  };
  return (
    <div className="w-screen h-screen">
      {!showSlide && (
        <div className="absolute z-20 flex flex-col items-center justify-center w-full h-full text-white">
          <motion.div
            className="flex flex-col max-w-lg p-2 m-2 rounded-lg bg-zinc-700"
            animate={
              showModal
                ? {}
                : {
                  opacity: [1, 0],
                }
            }
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.5],
              repeatDelay: 3,
            }}
          >

            {/* {navigator.userAgent.indexOf("Chrome") > -1 ? ( */}
            {true ? (
              <>
                <p className="text-white text-[20px] text-center pt-3.5 font-bold">
                  Welcome to the Sindarin AI Pitch Deck.
                </p>
                <p className="text-white text-[18px] text-center pt-3.5 pb-7">
                  This deck is best experienced on desktop. Mobile Safari is not supported.
                </p>
                <button
                  type="button"
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-slate-950 hover:bg-zinc-900"
                  onClick={handleBegin}
                >
                  <p>Click to begin</p>
                  {isLoadingPersona && (
                    <div className="p-l150">
                      <svg
                        className="w-5 h-5 ml-3 mr-3 text-white animate-spin justify-right"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  )}
                </button>
              </>
            ) : (
              <p className="text-white text-[20px] text-center font-inter">
                Sorry, the AI pitch deck is undergoing maintenance. It should be back up on October 1 or 2.
              </p>
            )}
          </motion.div>
        </div>
      )}
      {isLimitReached && (
        <div className="absolute z-50 flex flex-col items-center justify-center w-full h-full ">
          <motion.div

            className="w-[500px] bg-zinc-700 flex flex-col justify-center items-center rounded-lg p-2"
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 2 }}
          >
            <p className="text-xl text-white">
              You've hit your message limit!
            </p>
            <p className="mt-4 text-lg text-center text-white">
              Please head to <a
              className="mt-4 text-white underline"
              href="https://www.sindarin.tech/"
            >sindarin.tech</a> to sign up.
            </p>
          </motion.div>
        </div>
      )}
      <Slider curPage={curPage} showSlide={showSlide} />
      <PersonaClient
        shouldStartPersona={shouldStartPersona}
        onLimitReached={onLimitReached}
        onReady={onReady}
        stateMachineControls={stateMachineControls}
        setCurPage={setCurPage}
        curPage={curPage}
        onShowEmail={() => { setShowEmail(true) }}
        onHideEmail={() => { setShowEmail(false) }}
      />
      {curPage == 1 && showSlide && (
        <Popup
          wait={6}
          gap={true}
          text1={'To navigate the deck, simply chat with Ada.'}
          text2={'Ask for the next slide or skip around.'}
        />
      )}
      {curPage !== 1 && showSlide && (
        <Popup
          wait={16}
          text1={'Feel free to ask Ada to elaborate'}
          text2={'on the contents of any slide.'}
        />
      )}
      <div className="absolute z-20 text-white top-4 left-4">
        <p className="text-white text-[18px] text-center">
          {curPage}/9
        </p>
      </div>
      {showEmail && (
        <div className="absolute z-20 flex flex-col items-center justify-center w-full h-full ">
          <motion.div
            className="w-[300px] bg-zinc-700 flex flex-col justify-center items-center rounded-lg p-2 text-center pt-4"
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.25 }}
          >
            <p className="text-xl text-white">
              Click to send an email to brian@sindarinventures.com
            </p>
            <br />
            <button
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out rounded-md shadow bg-slate-950 hover:bg-zinc-700"
              onClick={() => window.location.href = "mailto:brian@sindarinventures.com"}
            >
              Get in touch
            </button>
          </motion.div>
        </div>
      )}

    </div>
  );
}

export default App;
