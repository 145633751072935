import { motion } from "framer-motion";

const Popup = (props) => {
  return (
    <div className="absolute z-20 flex flex-col-reverse items-start justify-start w-full h-full ml-4 text-white">
      <motion.div
        className="flex flex-col w-auto h-auto p-2 pl-4 pr-4 mb-4 bg-opacity-50 rounded-lg bg-zinc-700"
        animate={{
          opacity: [0, 1],
        }
        }
        transition={{
          delay: props.wait || 4,
          duration: 4,
          ease: "easeInOut",
          // ease: "easeOutIn",
          times: [0, 1],
          repeat: Infinity,
          repeatType: "reverse",
          repeatDelay: 36,
        }}
      >
        <p className="text-white text-[18px] text-center pt-3.5">
          {props.text1}
        </p>
        {
          props.gap ? (
            <p className="text-white text-[18px] text-center pt-3.5 pb-3.5">
              {props.text2}
            </p>
          ) :
            (
              <p className="text-white text-[18px] text-center pb-3.5">
                {props.text2}
              </p>
            )
        }

      </motion.div>
    </div>
  )
};

export default Popup;