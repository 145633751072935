import background2 from "../../assets/image/background2.png";
import image2 from "../../assets/image/image2.png";

const Slide2 = () => {
  return (
    <div className="absolute z-10 flex w-full h-full">
      <div className="absolute flex w-full h-full">
        <img
          className="w-1/2 h-full"
          src={background2}
          alt="background2"
        />
        <img
          className="w-1/2 h-full"
          src={image2}
          alt="image2"
        />
      </div>
      <div className="absolute z-10 flex flex-col w-1/2 gap-8 px-8 pt-16 text-white">
        <p className="text-[24px] sm:text-[48px] font-bold">
          Speech is the original human interface.
        </p>
        <p className="text-[15px] sm:text-[25px] self-start">
          Writing was a way to transmit speech over long distances and times.
        </p>
        <p className="text-[15px] sm:text-[25px] self-start">
          Apps were a way to structure human data for computers.
        </p>
        <p className="text-[15px] sm:text-[25px] self-start">
          Modern AI obviates both.
        </p>
      </div>
    </div>
  );
};

export default Slide2;
