import background from "../../assets/image/background.png";
import chart from "../../assets/image/chart.png";
import comment1 from "../../assets/image/comment1.png";
import comment2 from "../../assets/image/comment2.png";
import comment3 from "../../assets/image/comment3.png";
import comment4 from "../../assets/image/comment4.png";
import comment5 from "../../assets/image/comment5.jpeg";
import comment6 from "../../assets/image/comment6.png";
import comment7 from "../../assets/image/comment7.jpeg";

const Slide8 = () => {
  return (
    <div className="absolute z-10 flex w-full h-full text-white">
      <div className="w-full h-full flex flex-col z-10 px-[5%] items-center">
        <p className="text-[24px] sm:text-[48px] font-bold pt-12 z-10 tracking-wider">
          Buzz is growing.
        </p>
        <div className="z-10 flex flex-col gap-2 pt-8 tracking-wider">
          <p className="text-[15px] sm:text-[25px] pb-4">
            &#8226; 18 customers including SaaS, retail, gaming, and military
            training are on our waitlist.
          </p>
          <p className="text-[15px] sm:text-[25px] pb-4">
            &#8226; 59 visitors have started our "Create a Persona" form.
          </p>
          <img src={chart} alt="chart" className="w-[30%] self-center" />
          <p className="text-[15px] sm:text-[25px] pb-4 pt-4">
            &#8226; Our cheeky marketing stunt, smarterchild.chat, is turning
            heads.
          </p>
          <div className="flex gap-2">
            <div className="flex flex-col gap-2 w-[80%]">
              <div className="flex gap-2">
                <img src={comment1} alt="comment1" className="w-[33%] h-fit" />
                <img src={comment2} alt="comment2" className="w-[33%] h-fit" />
                <img src={comment3} alt="comment3" className="w-[33%] h-fit" />
              </div>
              <div className="flex gap-2">
                <img src={comment7} alt="comment7" className="w-[33%] h-fit" />
                <img src={comment4} alt="comment4" className="w-[33%]" />
                <img src={comment5} alt="comment5" className="w-[33%] h-fit" />
              </div>
            </div>
            <div className="flex gap-2 w-[15%]">
              <img src={comment6} alt="comment6" className="w-full h-fit" />
            </div>
          </div>
        </div>
      </div>
      <img
        src={background}
        alt="background"
        className="absolute w-full h-full"
      />
    </div>
  );
};

export default Slide8;
