import background from "../../assets/image/background.png";
import codeImg from "../../assets/image/code.png";

const Slide5 = () => {
  return (
    <div className="absolute z-10 flex w-full h-full">
      <div className="flex flex-col w-[50%] text-white gap-4 z-10 pt-16 px-[8%]">
        <p className="text-[24px] sm:text-[48px] font-bold pb-6">Dead simple to configure.</p>
        <p className="text-[15px] sm:text-[25px]">
          &#8226; Client SDKs integrate with just a few lines of code.
        </p>
        <p className="text-[15px] sm:text-[25px]">&#8226; Personas emit JSON, like any other input.</p>
        <p className="text-[15px] sm:text-[25px]">&#8226; Stateful updates maintain awareness.</p>
        <p className="text-[15px] sm:text-[25px]">
          &#8226; Define behavior with system prompts, action schemas and plugins.
        </p>
      </div>
      <div className="z-10 flex">
        <img src={codeImg} alt="image2" className="w-full h-full" />
      </div>
      <img
        src={background}
        alt="background"
        className="fixed w-full h-full"
      />
    </div>
  );
};

export default Slide5;
