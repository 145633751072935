import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";
import PersonaClientNPM from "@sindarin/persona";

const pageDescriptions = {
  '1': `The home screen with the word "Sindarin" in small text and the word "Persona" in large text below it.`,
  '2': `On the left half of the screen there is text that says from top to bottom: "Speech is the original human interface"\n"Writing was a way to transmit speech over long distances and times."\n"Apps were a way to structure human data for computers."\n"Modern AI obviates both." On the right half of the screen is an image of ancient and old looking texts, writing and typing equipment, symbolizing humanity's history of writing and typing.`,
  '3': `The header says "Voice assistants are out." Below that is a list of bullet points that say from top to bottom: "Wake words are clumsy."\nDecision trees are stifling"\n"One-shot requests are often misheard or cut short."\n"Turn-taking is stilted if it exists."\n"Agents are painful to configure." Along the bottom of the screen, there are four images of simple looking robots (voice assistants) that appear to be hand-drawn.`,
  '4': `The header says "Personas are in." Below that is a list of bullet points that say from top to bottom: "Truly conversational."\n"Fast, fluid, and empathic."\n"Flexible and adaptable."\n"Easily configured for any vertical." Along the bottom of the screen, there are four images of very advanced, sophisticated looking cyborgs (personas).`,
  '5': `The header says "Dead simple to configure." Below that is a list of bullet points that say from top to bottom: "Client SDKs integrate with just a few lines."\n"Personas emit JSON, like any other input."\n"Stateful updates maintain awareness."\n"Define behavior with system prompts, action schemas and plugins."\nOn the right half of the screen is an image of a code editor with an example of the Persona web client driving Ada's behavior, including changing pages and showing the contact email modal.`,
  '6': `The header says "The tech is hard." Below that is a list of bullet points that say from top to bottom: "Real-time conversation is an extreme technical challenge."\n"Our solution combines novel ML approaches with hardcore engineering."\n"Most of our pilot customers came to us after trying to build their own."`,
  '7': `The header says "The team is strong. Below is a sequence of images of the team members, including their names and titles.`,
  '8': `The header says "Buzz is growing." Below that is a list of bullet points that say from top to bottom: "16 customers including SaaS, retail, gaming, and military training are on our waitlist."\n"40 visitors have started our 'Create a Persona' form."\n"Our cheeky marketing stunt, smarterchild.chat, is turning heads." Smarterchild.chat is a marketing stunt that we created to show off our technology. On the page is also a graph of the number of visitors to our main website over time, and below that a number of screenshots of twitter comments celebrating our technology.`,
  '9': `The page says that for more information please contact our sales team.`,
}

const PersonaClient = (props) => {
  const [personaClient, setPersonaClient] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [didSetListeners, setDidSetListeners] = useState(false);
  const [didStartPersona, setDidStartPersona] = useState(false);
  const [lastMessageMetadata, setLastMessageMetadata] = useState(null);

  const propsRef = useRef(props);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);
  

  useEffect(() => {
    console.log("loading persona client");
    const apiKey = "f2ceef24-b174-4046-813a-843025f86337";
    const personaClient = new PersonaClientNPM(apiKey);
    setPersonaClient(personaClient);
  }, []);

  useEffect(() => {
    if (personaClient && isReady && !didSetListeners) {
      personaClient.on("user_speech_started", () => {
        // console.log('user_speech_started')
        // handleEvent('Listening');
      });

      personaClient.on("user_speech_ended", () => {
        // console.log('user_speech_ended')
        // handleEvent('Thinking');
      });

      personaClient.on("ai_speech_started", () => {
        // console.log('ai_speech_started')
        // handleEvent('Speaking');
      });

      personaClient.on("ai_speech_stopped", () => {
        // console.log('ai_speech_stopped')
        // handleEvent('Idle');
      });

      personaClient.on("connect_error", (error) => { });
      personaClient.on("disconnected", () => { });
      personaClient.on("action", (action) => {
        if (action.transcription) {
          return;
        }


        const { curPage } = propsRef.current;
        // console.log('json', detail);

        if (action.gotoPage && !(Number(action.gotoPage) === curPage)) {
          props.setCurPage(Number(action.gotoPage));
          personaClient.updateState({
            pageDescription: pageDescriptions[action.gotoPage] || '',
            currentPage: action.gotoPage
          });
        } else if (action.nextPage) {
          const nextPage = curPage + 1;
          if (nextPage <= 9) {
            props.setCurPage(nextPage);
            personaClient.updateState({
              pageDescription: pageDescriptions[nextPage] || '',
              currentPage: nextPage
            });
          }
        } else if (action.previousPage) {
          const previousPage = curPage - 1;
          if (previousPage >= 1) {
            props.setCurPage(previousPage);
            personaClient.updateState({
              pageDescription: pageDescriptions[previousPage] || '',
              currentPage: previousPage
            });
          }
        }


        if (Object.keys(action).includes("showEmail")) {
          if (action.showEmail === 'true') {
            props.onShowEmail();
          } else {
            props.onHideEmail();
          }
        }

        if (action.message_limit_reached) {
          props.onLimitReached();
        }
      });

      setDidSetListeners(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personaClient, props.onLimitReached, isReady, didSetListeners]);
  // startPersona function
  useEffect(() => {
    const getUserID = async () => {
      try {
        const res = await axios.get("https://geolocation-db.com/json/");

        const userId = res.data.IPv4;
        return userId.replace(/\./g, "_");
      } catch (e) {
        // use localstorage instead
        const userId = localStorage.getItem("scuid");
        if (userId) {
          return userId;
        } else {
          const userId = uuid();
          localStorage.setItem("scuid", userId);
          return userId;
        }
      }
    };

    if (personaClient && props.shouldStartPersona && !didStartPersona) {
      setDidStartPersona(true);
      console.log("starting persona client");
      const character = "sindarin-presenter";
      getUserID().then((userId) => {
        personaClient
          .init({userId, personaName: character})
          .then(() => {
            props.onReady();
            setIsReady(true);
            console.log("personaClient initialized");
          })
          .catch((err) => {
            console.log("personaClient init error", err);
            if (/You have/gi.test(err)) {
              props.onLimitReached();
            }
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personaClient,
    props.shouldStartPersona,
    props.onReady,
    didStartPersona,
    props.onLimitReached,
  ]);

  return null; // or return UI elements if needed
};

export default PersonaClient;
