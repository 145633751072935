import background from "../../assets/image/background.png";

const Slide10 = () => {
  return (
    <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full text-white">
      <p className="text-[35px] sm:text-[50px] font-bold pb-6 z-10 absolute top-8 tracking-wider">
      </p>
      <p className="text-[17px] sm:text-[30px] font-bold z-10 px-[5%] sm:px-[20%] text-center">
        FOR MORE INFORMATION, PLEASE CONTACT US AT SALES@SINDARINVENTURES.COM
      </p>
      <img
        src={background}
        alt="background"
        className="absolute w-full h-full"
      />
    </div>
  );
};

export default Slide10;
