import bgImg from "../../assets/image/background.png";
import logo from "../../assets/image/logo.png";
import { motion } from "framer-motion";

const Slide1 = (props) => {
  const { showSlide } = props;
  return (
    <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full text-white">
      <motion.p
        className="text-[20px] sm:text-[40px] tracking-widest z-10 opacity-0"
        animate={!showSlide ? {} : { opacity: 1, scale: 1 }}
        transition={{
          delay: 0.5,
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 100,
          },
        }}
      >
        SINDARIN
      </motion.p>
      <motion.p
        className="text-[40px] sm:text-[120px] tracking-widest font-bold z-10 opacity-0"
        initial={{ opacity: 0, scale: 0 }}
        animate={!showSlide ? {} : { opacity: 1, scale: 1 }}
        transition={{
          delay: 0.8,
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 100,
          },
        }}
      >
        PERSONA
      </motion.p>
      <img src={bgImg} alt="background" className="absolute w-full h-full" />
      <img
        src={logo}
        alt="logo"
        className="w-12 h-12 sm:w-[90px] sm:h-[90px] absolute bottom-[30px] right-[30px]"
      />
    </div>
  );
};

export default Slide1;
