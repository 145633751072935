import background from "../../assets/image/background.png";
import logo from "../../assets/image/logo.png";

const Slide9 = () => {
  return (
    <div className="absolute z-10 flex flex-col w-full h-full text-white">
      <p className="text-[28px] sm:text-[35px] font-bold pt-6 pb-6 z-10 tracking-wider pl-6">
        HOW WE WIN
      </p>
      <div className="w-full h-full flex sm:flex-row flex-col z-10 px-[5%] sm:gap-[5%] ">
        <div className="sm:w-[50%] flex flex-col gap-4">
          <p className="text-[#cdcdcd] text-[20px] sm:text-[40px]">
            Customer Obsession
          </p>
          <p className="text-[12px] sm:text-[20px]">
            We are obsessed with acquiring, serving, and retaining customers who
            want to pay us. AI is coll, but we build it only to iprove lives.
          </p>
          <p className="text-[#cdcdcd] text-[20px] sm:text-[40px] mt-4">
            Operational Excellence
          </p>
          <p className="text-[12px] sm:text-[20px]">
            Product development has changed. The striking images in this deck,
            for example, were generated in just a few minutes for a few bucks
            with Midjourney.
            <br /> <br />
            Every team member must have the potential, interest, and will to
            become an AI-augmented supersoldier, leveraging AI tools (ChatGPT 4,
            Github Co-Pilot for developers) in very part of their workstream.
            That way our team stays small, nimble, and efficient, able to
            respond to customer needs at a moment's notice.
            <br /> <br />
            We also recognize that we are operating in the most competitive area
            in technology during a bear market. We will not be purchasing pool
            tables or beer-on-tap any time soon.
          </p>
        </div>
        <div className="sm:w-[50%] flex flex-col gap-4 pt-16">
          <p className="text-[#cdcdcd] text-[20px] sm:text-[40px]">Taking the long view</p>
          <p className="text-[12px] sm:text-[20px]">
            There's no way around it: We are going to build Her. Certain
            platforms lik VR and retail are exceptionally-positioned to
            facilitate first contract between Personas and humans, but it
            doesn't end there.
            <br /> <br />
            Personas for businesses are going to be charming, helpful,
            personable, thoughtful-- the list goes on. Who wouldn't want a
            friend like that all the time?
            <br /> <br />
            As iron sharpens iron, serving our exceptional business customers
            (and their customers) will force us to engineer robust, reliable,
            useful solutions that survive contract with the demands of the free
            market. Along the way we will generate tremendous learnings and IP
            that will be difficult to replicate.
          </p>
        </div>
      </div>
      <img
        src={background}
        alt="background"
        className="fixed w-full h-full"
      />
      <img
        src={logo}
        alt="logo"
        className="w-12 h-12 sm:w-[90px] sm:h-[90px] fixed bottom-[30px] right-[30px]"
      />
    </div>
  );
};

export default Slide9;
