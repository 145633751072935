import background from "../../assets/image/background.png";
import avatar1 from "../../assets/image/avatar1.png";
import avatar2 from "../../assets/image/avatar2.png";
import avatar3 from "../../assets/image/avatar3.png";
import avatar4 from "../../assets/image/avatar4.png";

const Slide4 = () => {
  return (
    <div className="absolute z-10 flex flex-col items-center w-full h-full">
      <p className="text-white text-[24px] sm:text-[48px] font-bold z-10 py-8 tracking-wider px-[5%]">
        Personas are in.
      </p>
      <div className="flex flex-col gap-4 z-10 text-white self-start px-[5%] tracking-wide text-[15px] sm:text-[24px] sm:pb-0 pb-6">
        <p>&#8226; Truly conversational.</p>
        <p>&#8226; Fast, fluid, and empathic.</p>
        <p>&#8226; Flexible and adaptable.</p>
        <p>&#8226; Easily configured for any vertical.</p>
      </div>
      <div className="z-10 flex flex-col mt-auto sm:flex-row">
        <div className="flex sm:w-[50%]">
          <img src={avatar1} alt="avatar1" className="w-[50%]" />
          <img src={avatar2} alt="avatar2" className="w-[50%]" />
        </div>
        <div className="flex sm:w-[50%]">
          <img src={avatar3} alt="avatar3" className="w-[50%]" />
          <img src={avatar4} alt="avatar4" className="w-[50%]" />
        </div>
      </div>
      <img
        src={background}
        alt="background"
        className="absolute w-full h-full"
      />
    </div>
  );
};

export default Slide4;
